import React, { Component } from "react";

class Text extends Component {
    render() {
        return (
            <div style={{ color: "black" }}>
                <p className="oldstandardtt timeline_description" style={{ padding: "0", fontSize: "23px" }}>
                    <strong>"So you think that money is the root of all evil?"</strong> said Francisco d'Anconia. "Have
                    you ever asked what is the root of money? Money is a tool of exchange, which can't exist unless
                    there are goods produced and men able to produce them. Money is the material shape of the principle
                    that men who wish to deal with one another must deal by trade and give value for value. Money is not
                    the tool of the moochers, who claim your product by tears, or of the looters, who take it from you
                    by force. Money is made possible only by the men who produce. Is this what you consider evil?
                    <br />
                    <br />
                    When you accept money in payment for your effort, you do so only on the conviction that you will
                    exchange it for the product of the effort of others. It is not the moochers or the looters who give
                    value to money. Not an ocean of tears not all the guns in the world can transform those pieces of
                    paper in your wallet into the bread you will need to survive tomorrow. Those pieces of paper, which
                    should have been gold, are a token of honor— your claim upon the energy of the men who produce. Your
                    wallet is your statement of hope that somewhere in the world around you there are men who will not
                    default on that moral principle which is the root of money, Is this what you consider evil?
                    <br />
                    <br />
                    Have you ever looked for the root of production? Take a look at an electric generator and dare tell
                    yourself that it was created by the muscular effort of unthinking brutes. Try to grow a seed of
                    wheat without the knowledge left to you by men who had to discover it for the first time. Try to
                    obtain your food by means of nothing but physical motions— and you’ll learn that man’s mind is the
                    root of all the goods produced and of all the wealth that has ever existed on earth.
                    <br />
                    <br />
                    But you say that money is made by the strong at the expense of the weak? What strength do you mean?
                    It is not the strength of guns or muscles. Wealth is the product of man’s capacity to think. Then is
                    money made by the man who invents a motor at the expense of those who did not invent it? Is money
                    made by the intelligent at the expense of the fools? By the able at the expense of the incompetent?
                    By the ambitious at the expense of the lazy? Money is made— before it can be looted or mooched— made
                    by the effort of every honest man, each to the extent of his ability. An honest man is one who knows
                    that he can’t consume more than he has produced.’
                    <br />
                    <br />
                    To trade by means of money is the code of the men of goodwill. Money rests on the axiom that every
                    man is the owner of his mind and his effort. Money allows no power to prescribe the value of your
                    effort except the voluntary choice of the man who is willing to trade you his effort in return.
                    Money permits you to obtain for your goods and your labor that which they are worth to the men who
                    buy them, but no more. Money permits no deals except those to mutual benefit by the unforced
                    judgment of the traders. Money demands of you the recognition that men must work for their own
                    benefit, not for their own injury, for their gain, not their loss— the recognition that they are not
                    beasts of burden, born to carry the weight of your misery— that you must offer them values, not
                    wounds— that the common bond among men is not the exchange of suffering, but the exchange of goods.
                    <br />
                    <br />
                    Money demands that you sell, not your weakness to men’s stupidity, but your talent to their reason;
                    it demands that you buy, not the shoddiest they offer, but the best that your money can find. And
                    when men live by trade— with reason, not force, as their final arbiter— it is the best product that
                    wins, the best performance, the man of best judgment and highest ability— and the degree of a man’s
                    productiveness is the degree of his reward. This is the code of existence whose tool and symbol is
                    money. Is this what you consider evil?
                    <br />
                    <br />
                    But money is only a tool. It will take you wherever you wish, but it will not replace you as the
                    driver. It will give you the means for the satisfaction of your desires, but it will not provide you
                    with desires. Money is the scourge of the men who attempt to reverse the law of causality–the men
                    who seek to replace the mind by seizing the products of the mind.
                    <br />
                    <br />
                    Money will not purchase happiness for the man who has no concept of what he wants: money will not
                    give him a code of values, if he’s evaded the knowledge of what to value, and it will not provide
                    him with a purpose, if he’s evaded the choice of what to seek. Money will not buy intelligence for
                    the fool, or admiration for the coward, or respect for the incompetent. The man who attempts to
                    purchase the brains of his superiors to serve him, with his money replacing his judgment, ends up by
                    becoming the victim of his inferiors. The men of intelligence desert him, but the cheats and the
                    frauds come flocking to him, drawn by a law which he has not discovered: that no man may be smaller
                    than his money. Is this the reason why you call it evil?
                    <br />
                    Only the man who does not need it, is fit to inherit wealth— the man who would make his own fortune
                    no matter where he started. If an heir is equal to his money, it serves him; if not, it destroys
                    him. But you look on and you cry that money corrupted him. Did it? Or did he corrupt his money? Do
                    not envy a worthless heir; his wealth is not yours and you would have done no better with it. Do not
                    think that it should have been distributed among you; loading the world with fifty parasites instead
                    of one, would not bring back the dead virtue which was the fortune. Money is a living power that
                    dies without its root. Money will not serve the mind that cannot match it. Is this the reason why
                    you call it evil?
                    <br />
                    <br />
                    Money is your means of survival. The verdict you pronounce upon the source of your livelihood is the
                    verdict you pronounce upon your life. If the source is corrupt, you have damned your own existence.
                    Did you get your money by fraud? By pandering to men’s vices or men’s stupidity? By catering to
                    fools, in the hope of getting more than your ability deserves? By lowering your standards? By doing
                    work you despise for purchasers you scorn? If so, then your money will not give you a moment or a
                    penny’s worth of joy. Then all the things you buy will become, not a tribute to you, but a reproach;
                    not an achievement, but a reminder of shame. Then you’ll scream that money is evil. Evil, because it
                    would not pinch-hit for your self-respect? Evil, because it would not let you enjoy your depravity?
                    Is this the root of your hatred of money?
                    <br />
                    <br />
                    Money will always remain an effect and refuse to replace you as the cause. Money is the product of
                    virtue, but it will not give you virtue and it will not redeem your vices. Money will give you the
                    unearned, neither in matter nor in spirit. Is this the root of your hatred of money?
                    <br />
                    <br />
                    Or did you say it’s the love of money that’s the root of all evil? To love a thing is to know and
                    love its nature. To love money is to know and love the fact that money is the creation of the best
                    power within you, and your passkey to trade your effort for the effort of the best among men. It’s
                    the person who would sell his soul for a nickel, who is loudest in proclaiming his hatred of money—
                    and he has good reason to hate it. The lovers of money are willing to work for it. They know they
                    are able to deserve it.
                    <br />
                    <br />
                    Let me give you a tip on a clue to men’s characters: the man who damns money has obtained it
                    dishonorably; the man who respects it has earned it.
                    <br />
                    <br />
                    Run for your life from any man who tells you that money is evil. That sentence is the leper’s bell
                    of an approaching looter. So long as men live together on earth and need means to deal with one
                    another–their only substitute, if they abandon money, is the muzzle of a gun.
                    <br />
                    <br />
                    But money demands of you the highest virtues, if you wish to make it or to keep it. Men who have no
                    courage, pride or self-esteem, men who have no moral sense of their right to their money and are not
                    willing to defend it as they defend their life, men who apologize for being rich— will not remain
                    rich for long. They are the natural bait for the swarms of looters that stay under rocks for
                    centuries, but come crawling out at the first smell of a man who begs to be forgiven for the guilt
                    of owning wealth. They will hasten to relieve him of the guilt— and of his life, as he deserves.
                    <br />
                    <br />
                    Then you will see the rise of the men of the double standard— the men who live by force, yet count
                    on those who live by trade to create the value of their looted money— the men who are the
                    hitchhikers of virtue. In a moral society, these are the criminals, and the statutes are written to
                    protect you against them. But when a society establishes criminals- by-right and looters-by-law—men
                    who use force to seize the wealth of disarmed victims— then money becomes its creators’ avenger.
                    Such looters believe it safe to rob defenseless men, once they’ve passed a law to disarm them.
                    <br />
                    <br />
                    But their loot becomes the magnet for other looters, who get it from them as they got it. Then the
                    race goes, not to the ablest at production, but to those most ruthless at brutality. When force is
                    the standard, the murderer wins over the pickpocket. And then that society vanishes, in a spread of
                    ruins and slaughter.
                    <br />
                    <br />
                    Do you wish to know whether that day is coming? Watch money. Money is the barometer of a society’s
                    virtue. When you see that trading is done, not by consent, but by compulsion— when you see that in
                    order to produce, you need to obtain permission from men who produce nothing— when you see that
                    money is flowing to those who deal, not in goods, but in favors— when you see that men get richer by
                    graft and by pull than by work, and your laws don’t protect you against them, but protect them
                    against you— when you see corruption being rewarded and honesty becoming a self-sacrifice— you may
                    know that your society is doomed.
                    <br />
                    <br />
                    Money is so noble a medium that is does not compete with guns and it does not make terms with
                    brutality. It will not permit a country to survive as half- property, half-loot.
                    <br />
                    <br />
                    Whenever destroyers appear among men, they start by destroying money, for money is men’s protection
                    and the base of a moral existence. Destroyers seize gold and leave to its owners a counterfeit pile
                    of paper. This kills all objective standards and delivers men into the arbitrary power of an
                    arbitrary setter of values. Gold was an objective value, an equivalent of wealth produced. Paper is
                    a mortgage on wealth that does not exist, backed by a gun aimed at those who are expected to produce
                    it. Paper is a check drawn by legal looters upon an account which is not theirs: upon the virtue of
                    the victims. Watch for the day when it bounces, marked, ‘Account overdrawn.’
                    <br />
                    <br />
                    When you have made evil the means of survival, do not expect men to remain good. Do not expect them
                    to stay moral and lose their lives for the purpose of becoming the fodder of the immoral. Do not
                    expect them to produce, when production is punished and looting rewarded. Do not ask, ‘Who is
                    destroying the world? You are.
                    <br />
                    <br />
                    You stand in the midst of the greatest achievements of the greatest productive civilization and you
                    wonder why it’s crumbling around you, while you’re damning its lifeblood— money. You look upon money
                    as the savages did before you, and you wonder why the jungle is creeping back to the edge of your
                    cities. Throughout men’s history, money was always seized by looters of one brand or another, whose
                    names changed, but whose method remained the same: to seize wealth by force and to keep the
                    producers bound, demeaned, defamed, deprived of honor.
                    <br />
                    <br />
                    That phrase about the evil of money, which you mouth with such righteous recklessness, comes from a
                    time when wealth was produced by the labor of slaves— Slaves who repeated the motions once
                    discovered by somebody’s mind and left unimproved for centuries. So long as production was ruled by
                    force, and wealth was obtained by conquest, there was little to conquer, Yet through all the
                    centuries of stagnation and starvation, men exalted the looters, as aristocrats of the sword, as
                    aristocrats of birth, as aristocrats of the bureau, and despised the producers, as slaves, as
                    traders, as shopkeepers— As industrialists.
                    <br />
                    <br />
                    To the glory of mankind, there was, for the first and only time in history, a country of money— and
                    I have no higher, more reverent tribute to pay to America, for this means: a country of reason,
                    justice, freedom, production, achievement. For the first time, man’s mind and money were set free,
                    and there were no fortunes-by-conquest, but only fortunes-by-work, and instead of swordsmen and
                    slaves, there appeared the real maker of wealth, the greatest worker, the highest type of human
                    being— the self-made man— the American industrialist.
                    <br />
                    <br />
                    If you ask me to name the proudest distinction of Americans, I would choose— because it contains all
                    the others— the fact that they were the people who created the phrase ‘to make money.’ No other
                    language or nation had ever used these words before; men had always thought of wealth as a static
                    quantity— to be seized, begged, inherited, shared, looted or obtained as a favor. Americans were the
                    first to understand that wealth has to be created. The words ‘to make money’ hold the essence of
                    human morality.
                    <br />
                    <br />
                    Yet these were the words for which Americans were denounced by the rotted cultures of the looters’
                    continents. Now the looters’ credo has brought you to regard your proudest achievements as a
                    hallmark of shame, your prosperity as guilt, your greatest men, the industrialists, as blackguards,
                    and your magnificent factories as the product and property of muscular labor, the labor of
                    whip-driven slaves, like the pyramids of Egypt. The rotter who simpers that he sees no difference
                    between the power of the dollar and the power of the whip, ought to learn the difference on his own
                    hide— as, I think, he will.
                    <br />
                    <br />
                    Until and unless you discover that money is the root of all good, you ask for your own destruction.
                    When money ceases to be the tool by which men deal with one another, then men become the tools of
                    men. Blood, whips, and guns— or dollars. Take your choice— there is no other— and your time is
                    running out.
                </p>
            </div>
        );
    }
}

export default Text;
