import React, { Component } from "react";
import { Col, Row } from "antd";
import { Element } from "react-scroll";
import Text from "./Text";

export default class TextSection extends Component {
    render() {
        return (
            <Element name="test1" className="element">
                <Row
                    style={{
                        padding: "10vh 5vw",
                        background: "linear-gradient(16deg, rgba(220,220,220,1) 0%, rgba(255,255,255,1) 70%)"
                    }}
                >
                    <Col sm={24} md={8} className="scrollarea">
                        <h1 className="bebas_neue text_title">Money Speech</h1>
                        <h1 className="oldstandardtt text_subtitle">
                            by Francisco d'Anconia,
                            <br />
                            from Atlas Shrugged by Ayn Rand
                        </h1>
                    </Col>
                    <Col sm={24} md={16} style={{ padding: "0 1vw" }}>
                        <Text />
                    </Col>
                </Row>
            </Element>
        );
    }
}
