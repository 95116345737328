import React, { Component } from "react";
import Home from "./Home";
import Footer from "./Footer";
import TextSection from "../textSection/TextSection";
import "../../App.css";

class EvilMoneyPage extends Component {
    render() {
        return (
            <div className="App">
                <Home />
                <TextSection />
                <Footer />
            </div>
        );
    }
}

export default EvilMoneyPage;
