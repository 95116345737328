import React, { Component } from "react";
import { Parallax } from "react-parallax";

class Home extends Component {
    render() {
        return (
            <Parallax
                bgImage={require("../../static/burning-money.png")}
                bgImageAlt="Money being burned"
                strength={1000}
            >
                <div style={{ height: "85vh" }} />
            </Parallax>
        );
    }
}

export default Home;
